import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import "./style.css"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { ModalDataDatatables } from "./dataTable"
import toast from "react-hot-toast"
import axios from "axios"
import { base_url } from "customPages/baseurl"
import { uploadImage } from "customPages/baseurl/uploadImage"

const Cities = props => {
  document.title = "Cities | KSB - Admin Panel"
  const [markets, setCities] = useState([])
  const breadcrumbItems = [
    { title: "KSB", link: "#" },
    { title: "Cities", link: "#" },
  ]

  const addCity = async e => {
    let image = null
    if (addModal?.file) {
      image = await uploadImage(addModal?.file)
      delete addModal?.file
    }
    await axios
      .post(base_url + "add_city.php", {
        ...addModal?.data,
        image: image?.data,
      })
      .then(res => {
        console.log(res)
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setAddModal({
          open: false,
          data: {},
        })
        getCities()
      })
      .finally(() => {})
  }

  const getCities = async e => {
    await axios
      .get(base_url + "select_cities_with_markts.php")
      .then(res => {
        setCities(res?.data?.message)
      })
      .finally(() => {})
  }
  useEffect(() => {
    props.setBreadcrumbItems("Cities", breadcrumbItems)
    getCities()
  }, [])
  const [addModal, setAddModal] = useState({
    open: false,
    data: {},
    file: null,
  })
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle
                className="h4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>Cities</span>
                <button
                  className="btn btn-primary"
                  onClick={() => setAddModal({ ...addModal, open: true })}
                >
                  Add City
                </button>
              </CardTitle>
              <ModalDataDatatables
                data={markets}
                getMarkets={getCities}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={addModal?.open}
        toggle={() =>
          setAddModal({
            open: false,
            data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() =>
            setAddModal({
              open: false,
              data: {},
            })
          }
        >
          Add City
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="columnDiv">
              <label htmlFor="market_title">City Title (Arabic) </label>
              <input
                value={addModal?.data?.name_ar}
                onChange={e => {
                  setAddModal({
                    ...addModal,
                    data: {
                      ...addModal?.data,
                      name_ar: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="market_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="market_title">City Title (English)</label>
              <input
                value={addModal?.data?.name_en}
                onChange={e => {
                  setAddModal({
                    ...addModal,
                    data: {
                      ...addModal?.data,
                      name_en: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="market_title"
              />
            </div>
         
            <button
              style={{ marginTop: "10px" }}
              className="btn btn-success"
              onClick={() => addCity()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Cities)
