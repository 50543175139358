import withRouter from "components/Common/withRouter";
import MetisMenu from "metismenujs";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { menuItems } from "./data";

const SidebarContent = (props) => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    // Implement activation logic as needed
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    // Implement removal logic as needed
  };

  const activeMenu = useCallback(() => {
    // Implement active menu logic using menuItems array
  }, []);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    // Implement scroll logic as needed
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menuItems.map((menuItem) => (
              <MenuItem key={menuItem.id} menuItem={menuItem} />
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

const MenuItem = ({ menuItem }) => {
  return (
    <li>
      <Link to={menuItem.link} className={menuItem.subItems ? "has-arrow waves-effect" : "waves-effect"}>
        <i className={menuItem.icon}></i>
        {menuItem.badge && (
          <span className={`badge rounded-pill ${menuItem.badge.color} float-end`}>
            {menuItem.badge.text}
          </span>
        )}
        <span>{menuItem.label}</span>
      </Link>
      {menuItem.subItems && (
        <ul className="sub-menu">
          {menuItem.subItems.map((subItem) => (
            <li key={subItem.id}>
              <Link to={subItem.link}>{subItem.label}</Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
