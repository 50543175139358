import axios from "axios";
import { base_url } from '.';

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  return await axios.post(base_url + "image_uplouder.php", formData, {
    headers: {
      "Content-Type":"multipart/form-data"
    }
  });
};
