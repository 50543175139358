import axios from "axios"
import { base_url } from "customPages/baseurl"
import { uploadImage } from "customPages/baseurl/uploadImage"
import { useState } from "react"
import DataTable from "react-data-table-component"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const ModalDataDatatables = ({
  data,
  getCategories,
  productData,
  setProductData,
}) => {
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: cell => {
        return (
          <div className="btnsContainer">
            {" "}
         
            <div
              className="btn btn-primary"
              style={{ margin: "0 5px" }}
              onClick={e => {
                // console.log(cell)
                setEditModal({
                  open: true,
                  data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              Edit
            </div>
            <div
              className="btn btn-success"
              style={{ margin: "0 5px" }}
              onClick={e => {
                // console.log(cell)
                setShowHideModal({
                  open: true,
                  category_data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              {cell.hidden == 0 ? "Hide" : "Show"}
            </div>
            <div
              className="btn btn-danger"
              onClick={e => {
                console.log(cell)
                setDeleteModal({
                  open: true,
                  category_data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              Delete
            </div>
          </div>
        )
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Category Products</span>,
      sortable: true,

      cell: cell => {
        return (
          <div className="btnsContainer">
            {" "}
            <div
              className="btn btn-primary"
              style={{ margin: "0 5px" }}
              onClick={e => {
                // console.log(cell)
                setProductData({
                  category_id: cell,
                  file: null,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              Upload Products
            </div>
           
         
          </div>
        )
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Image</span>,
      selector: row => (
        <img
          onClick={() => setImage(row?.image)}
          src={row?.image}
          alt=""
          style={{
            width: "140px",
            height: "50px",
            objectFit: "contain",
            padding: "10px",
            cursor: "pointer",
          }}
        />
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name (Arabic)</span>,
      selector: row => row.name_ar,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name (English)</span>,
      selector: row => row.name_en,
      sortable: true,
    },
  ]
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    category_data: {},
  })
  const handleDelete = async e => {
    await axios
      .post(base_url + "delete_caregory.php", {
        ...deleteModal?.category_data,
      })
      .then(res => {
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setDeleteModal({
          open: false,
          category_data: {},
        })
        if (getCategories) getCategories()
      })
      .finally(() => {})
  }

  const [showHideModal, setShowHideModal] = useState({
    open: false,
    category_data: {},
  })
  const handleShowHide = async e => {
    await axios
      .post(base_url + "update_caregories_hidden.php", {
        ...{
          category_id: showHideModal?.category_data?.category_id,
          hidden: showHideModal?.category_data?.hidden == 0 ? 1 : 0,
        },
      })
      .then(res => {
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setShowHideModal({
          open: false,
          category_data: {},
        })
        if (getCategories) getCategories()
      })
      .finally(() => {})
  }

  const [editModal, setEditModal] = useState({
    open: false,
    data: {},
    file: null,
  })
  const editCategory = async e => {
    let image = null
    if (editModal?.file) {
      image = await uploadImage(editModal?.file)
      delete editModal?.file
    }
    await axios
      .post(base_url + "update_category_data.php", {
        ...editModal?.data,
        image: image?.data ? image?.data : editModal?.data?.image,
      })
      .then(res => {
        console.log(res)
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setEditModal({
          open: false,
          data: {},
        })
        if (getCategories) getCategories()
      })
      .finally(() => {})
  }
  return (
    <>
      <DataTable columns={columns} data={data} pagination />
      <Modal
        isOpen={deleteModal?.open}
        toggle={() =>
          setDeleteModal({
            open: false,
            category_data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() =>
            setDeleteModal({
              open: false,
              category_data: {},
            })
          }
        >
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <h1>Are You Sure?</h1>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Confirm Delete
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              setDeleteModal({
                open: false,
                category_data: {},
              })
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showHideModal?.open}
        toggle={() =>
          setShowHideModal({
            open: false,
            category_data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() =>
            setShowHideModal({
              open: false,
              category_data: {},
            })
          }
        >
          Confirm Hide/Show
        </ModalHeader>
        <ModalBody>
          <h1>
            Are You Sure{" "}
            {showHideModal?.category_data?.hidden == 0 ? "Hide" : "Show"} ?
          </h1>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleShowHide}>
            Confirm{" "}
            {showHideModal?.category_data?.hidden == 0 ? "Hide" : "Show"}
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              setShowHideModal({
                open: false,
                category_data: {},
              })
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={editModal?.open}
        toggle={() =>
          setEditModal({
            open: false,
            data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() =>
            setEditModal({
              open: false,
              data: {},
            })
          }
        >
          Add Category
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="columnDiv">
              <label htmlFor="category_title">Category Title (Arabic) </label>
              <input
                value={editModal?.data?.name_ar}
                onChange={e => {
                  setEditModal({
                    ...editModal,
                    data: {
                      ...editModal?.data,
                      name_ar: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="category_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="category_title">Category Title (English)</label>
              <input
                value={editModal?.data?.name_en}
                onChange={e => {
                  setEditModal({
                    ...editModal,
                    data: {
                      ...editModal?.data,
                      name_en: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="category_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="category_file">Category Image</label>
              <input
                onChange={e => {
                  setEditModal({
                    ...editModal,
                    file: e.target.files[0],
                  })
                }}
                className="form-control"
                type="file"
                name="category_file"
              />
            </div>
            <button
              style={{ marginTop: "10px" }}
              className="btn btn-success"
              onClick={() => editCategory()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
