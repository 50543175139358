import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import "./style.css"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { ModalDataDatatables } from "./dataTable"
import toast from "react-hot-toast"
import axios from "axios"
import { base_url } from "customPages/baseurl"
import { uploadImage } from "customPages/baseurl/uploadImage"
import { uploadExcel } from "customPages/baseurl/uploadFile"

const Catgeories = props => {
  document.title = "Categories | KSB - Admin Panel"
  const [categories, setCategories] = useState([])
  const breadcrumbItems = [
    { title: "KSB", link: "#" },
    { title: "Categories", link: "#" },
  ]

  const [productData, setProductData] = useState({
    file: null,
    category_id: null,
  })

  const addCategory = async e => {
    let image = null
    if (addModal?.file) {
      image = await uploadImage(addModal?.file)
      delete addModal?.file
    }
    await axios
      .post(base_url + "add_categories.php", {
        ...addModal?.data,
        image: image?.data,
      })
      .then(res => {
        console.log(res)
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setAddModal({
          open: false,
          data: {},
        })
        getCategories()
      })
      .finally(() => {})
  }

  const uploadExcelFile = async e => {
    let image = null
    if (productData?.file) {
      const formData = new FormData()
      formData.append("file_attachment", productData?.file)
      formData.append("category_id", productData?.category_id?.category_id)
      formData.append("language_type", productData?.language)
      image = await uploadExcel(productData?.file, formData)
      setProductData({
        file: null,
        category_id: null,
        language: null,
      })
      toast.success(image?.data, {
        icon: null,
        style: {
          boxShadow: "1px 2px 24px -2px grey",
          background: "black",
          color: "white",
          fontSize: "20px ",
          width: "fit-content",
          fontWeight: "700",
        },
      })
    }
  }

  const getCategories = async e => {
    await axios
      .get(base_url + "select_categories.php")
      .then(res => {
        setCategories(res?.data?.message)
      })
      .finally(() => {})
  }
  useEffect(() => {
    props.setBreadcrumbItems("Categories", breadcrumbItems)
    getCategories()
  }, [])
  const [addModal, setAddModal] = useState({
    open: false,
    data: {},
    file: null,
  })
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle
                className="h4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>Categories</span>
                <button
                  className="btn btn-primary"
                  onClick={() => setAddModal({ ...addModal, open: true })}
                >
                  Add Category
                </button>
              </CardTitle>
              <ModalDataDatatables
                data={categories}
                getCategories={getCategories}
                setProductData={setProductData}
                productData={productData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={addModal?.open}
        toggle={() =>
          setAddModal({
            open: false,
            data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() =>
            setAddModal({
              open: false,
              data: {},
            })
          }
        >
          Add Category
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="columnDiv">
              <label htmlFor="category_title">Category Title (Arabic) </label>
              <input
                value={addModal?.data?.name_ar}
                onChange={e => {
                  setAddModal({
                    ...addModal,
                    data: {
                      ...addModal?.data,
                      name_ar: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="category_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="category_title">Category Title (English)</label>
              <input
                value={addModal?.data?.name_en}
                onChange={e => {
                  setAddModal({
                    ...addModal,
                    data: {
                      ...addModal?.data,
                      name_en: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="category_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="category_file">Category Image</label>
              <input
                onChange={e => {
                  setAddModal({
                    ...addModal,
                    file: e.target.files[0],
                  })
                }}
                className="form-control"
                type="file"
                name="category_file"
              />
            </div>
            <button
              style={{ marginTop: "10px" }}
              className="btn btn-success"
              onClick={() => addCategory()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={productData?.category_id}
        toggle={() =>
          setProductData({
            file: null,
            category_id: null,
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() =>
            setProductData({
              file: null,
              category_id: null,
            })
          }
        >
          Add Products
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="columnDiv">
              <label htmlFor="category_file">Excel File</label>
              <input
                onChange={e => {
                  setProductData({
                    ...productData,
                    file: e.target.files[0],
                  })
                }}
                className="form-control"
                type="file"
                name="category_file"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="category_file">Excel File Language</label>
              <select
                onChange={e => {
                  setProductData({
                    ...productData,
                    language: e.target.value,
                  })
                }}
              >
                <option value="english">English</option>
                <option value="english">Arabic</option>
              </select>
            </div>
            <button
              style={{ marginTop: "10px" }}
              className="btn btn-success"
              onClick={() => uploadExcelFile()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Catgeories)
