import axios from "axios"
import { base_url } from "customPages/baseurl"
import { uploadImage } from "customPages/baseurl/uploadImage"
import { useState } from "react"
import DataTable from "react-data-table-component"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export const ModalDataDatatables = ({ data, getMarkets }) => {
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,

      cell: cell => {
        return (
          <div className="btnsContainer">
            <div
              className="btn btn-primary"
              style={{ margin: "0 5px" }}
              onClick={e => {
                // console.log(cell)
                setEditModal({
                  open: true,
                  data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              Edit
            </div>
            <div
              className="btn btn-info"
              onClick={() => {
                setAssignModal({
                  open: true,
                  markets: cell.markts,
                  city_id: cell?.city_id,
                })
              }}
            >
              Assign Markets
            </div>{" "}
            <div
              className="btn btn-success"
              style={{ margin: "0 5px" }}
              onClick={e => {
                // console.log(cell)
                setShowHideModal({
                  open: true,
                  market_data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              {cell.hidden == 0 ? "Hide" : "Show"}
            </div>
            <div
              className="btn btn-danger"
              onClick={e => {
                console.log(cell)
                setDeleteModal({
                  open: true,
                  market_data: cell,
                })
              }}
            >
              <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
              Delete
            </div>
          </div>
        )
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Name (Arabic)</span>,
      selector: row => row.name_ar,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Name (English)</span>,
      selector: row => row.name_en,
      sortable: true,
    },
  ]
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    market_data: {},
  })
  const handleDelete = async e => {
    delete deleteModal?.markts
    await axios
      .post(
        base_url + "delete_city.php",
        JSON.stringify({
          ...deleteModal?.market_data,
        }),
      )
      .then(res => {
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setDeleteModal({
          open: false,
          market_data: {},
        })
        if (getMarkets) getMarkets()
      })
      .finally(() => {})
  }

  const [showHideModal, setShowHideModal] = useState({
    open: false,
    market_data: {},
  })
  const handleShowHide = async e => {
    delete showHideModal?.markts
    await axios
      .post(
        base_url + "update_city_hidden.php",
        JSON.stringify({
          ...{
            city_id: showHideModal?.market_data?.city_id,
            hidden: showHideModal?.market_data?.hidden == 0 ? 1 : 0,
          },
        }),
      )
      .then(res => {
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setShowHideModal({
          open: false,
          market_data: {},
        })
        if (getMarkets) getMarkets()
      })
      .finally(() => {})
  }

  const [editModal, setEditModal] = useState({
    open: false,
    data: {},
    file: null,
  })
  const editMarket = async e => {
    let image = null
    delete editModal?.markts
    if (editModal?.file) {
      image = await uploadImage(editModal?.file)
      delete editModal?.file
    }
    await axios
      .post(
        base_url + "edit_city_data.php",
        JSON.stringify({
          ...editModal?.data,
          image: image?.data ? image?.data : editModal?.data?.image,
        }),
      )
      .then(res => {
        console.log(res)
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setEditModal({
          open: false,
          data: {},
        })
        if (getMarkets) getMarkets()
      })
      .finally(() => {})
  }

  const [assignModal, setAssignModal] = useState({
    open: false,
    markets: [],
    city_id: null,
  })
  const handleAssignMarkets = async () => {
    // Implement logic to save market assignments
    // You can pass the checked markets and the city_id to your API endpoint
    const selectedMarkets = assignModal.markets.filter(
      market => market.in_this_city,
    )

    await axios
      .post(
        base_url + "assign_markt_to_city.php",
        JSON.stringify({
          city_id: assignModal.city_id,
          markts_ids: selectedMarkets?.map(item => item?.market_id)?.join(","),
        }),
      )
      .then(res => {
        toast.success(res?.data?.message, {
          icon: null,
          style: {
            boxShadow: "1px 2px 24px -2px grey",
            background: "black",
            color: "white",
            fontSize: "20px ",
            width: "fit-content",
            fontWeight: "700",
          },
        })
        setDeleteModal({
          open: false,
          market_data: {},
        })
        if (getMarkets) getMarkets()
      })
      .finally(() => {})
    // Make an API call to save these selections as needed
    setAssignModal({ open: false, markets: [], city_id: null })
  }
  return (
    <>
      <DataTable columns={columns} data={data} pagination />
      <Modal
        isOpen={deleteModal?.open}
        toggle={() =>
          setDeleteModal({
            open: false,
            market_data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() =>
            setDeleteModal({
              open: false,
              market_data: {},
            })
          }
        >
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <h1>Are You Sure?</h1>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Confirm Delete
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              setDeleteModal({
                open: false,
                market_data: {},
              })
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showHideModal?.open}
        toggle={() =>
          setShowHideModal({
            open: false,
            market_data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() =>
            setShowHideModal({
              open: false,
              market_data: {},
            })
          }
        >
          Confirm Hide/Show
        </ModalHeader>
        <ModalBody>
          <h1>
            Are You Sure{" "}
            {showHideModal?.market_data?.hidden == 0 ? "Hide" : "Show"} ?
          </h1>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleShowHide}>
            Confirm {showHideModal?.market_data?.hidden == 0 ? "Hide" : "Show"}
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              setShowHideModal({
                open: false,
                market_data: {},
              })
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={editModal?.open}
        toggle={() =>
          setEditModal({
            open: false,
            data: {},
          })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() =>
            setEditModal({
              open: false,
              data: {},
            })
          }
        >
          Edit Market
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="columnDiv">
              <label htmlFor="market_title">Market Title (Arabic) </label>
              <input
                value={editModal?.data?.name_ar}
                onChange={e => {
                  setEditModal({
                    ...editModal,
                    data: {
                      ...editModal?.data,
                      name_ar: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="market_title"
              />
            </div>
            <div className="columnDiv">
              <label htmlFor="market_title">Market Title (English)</label>
              <input
                value={editModal?.data?.name_en}
                onChange={e => {
                  setEditModal({
                    ...editModal,
                    data: {
                      ...editModal?.data,
                      name_en: e.target.value,
                    },
                  })
                }}
                className="form-control"
                type="text"
                name="market_title"
              />
            </div>

            <button
              style={{ marginTop: "10px" }}
              className="btn btn-success"
              onClick={() => editMarket()}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={assignModal.open}
        toggle={() =>
          setAssignModal({ open: false, markets: [], city_id: null })
        }
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() =>
            setAssignModal({ open: false, markets: [], city_id: null })
          }
        >
          Assign Markets to City
        </ModalHeader>
        <ModalBody>
          <h5>Select Markets for City ID: {assignModal.city_id}</h5>
          {assignModal.markets.map(market => (
            <div key={market.market_id}>
              <input
                type="checkbox"
                checked={market.in_this_city}
                onClick={e => {
                  const updatedMarkets = assignModal.markets.map(m => {
                    if (m.market_id === market.market_id) {
                      return { ...m, in_this_city: !e.target.checked }
                    }
                    return m
                  })
                  setAssignModal(prev => ({ ...prev, markets: updatedMarkets }))
                }}
              />
              <span>
                {market.market_name_en} (Arabic: {market.market_name_ar})
              </span>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleAssignMarkets}>
            Save Assignments
          </Button>
          <Button
            color="secondary"
            onClick={() =>
              setAssignModal({ open: false, markets: [], city_id: null })
            }
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
