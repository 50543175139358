export const menuItems = [
  {
    id: 1,
    link: "/category",
    icon: "mdi mdi-view-dashboard",
    badge: { text: "2", color: "bg-primary" },
    label: "Category",
  },
  {
    id: 2,
    link: "/Cities",
    icon: "mdi mdi-view-dashboard",
    badge: { text: "2", color: "bg-primary" },
    label: "Cities",
  },
  {
    id: 3,
    link: "/markets",
    icon: "mdi mdi-view-dashboard",
    badge: { text: "2", color: "bg-primary" },
    label: "Markets",
  }, // {
  //   id: 1,
  //   link: "/dashboard",
  //   icon: "mdi mdi-view-dashboard",
  //   badge: { text: "2", color: "bg-primary" },
  //   label: "Dashboard",
  // },
  // {
  //   id: 2,
  //   link: "/calendar",
  //   icon: "mdi mdi-calendar-check",
  //   label: "Calendar",
  // },
  // {
  //   id: 3,
  //   link: "/email",
  //   icon: "mdi mdi-email-outline",
  //   label: "Email",
  //   subItems: [
  //     { id: 31, link: "/email-inbox", label: "Inbox" },
  //     { id: 32, link: "/email-read", label: "Email Read" },
  //     { id: 33, link: "/email-compose", label: "Email Compose" },
  //   ],
  // },
  // {
  //   id: 4,
  //   link: "/chat",
  //   icon: "mdi mdi-chat-processing-outline",
  //   badge: { text: "Hot", color: "bg-danger" },
  //   label: "Chat",
  // },
  // {
  //   id: 5,
  //   link: "/kanbanboard",
  //   icon: "mdi mdi-billboard",
  //   badge: { text: "New", color: "bg-success" },
  //   label: "Kanban Board",
  // },
  // {
  //   id: 6,
  //   label: "Components",
  //   subItems: [
  //     {
  //       id: 61,
  //       link: "/ui-elements",
  //       icon: "mdi mdi-buffer",
  //       label: "UI Elements",
  //       subItems: [
  //         { id: 611, link: "/ui-alerts", label: "Alerts" },
  //         { id: 612, link: "/ui-buttons", label: "Buttons" },
  //         { id: 613, link: "/ui-badge", label: "Badge" },
  //         { id: 614, link: "/ui-cards", label: "Cards" },
  //         { id: 615, link: "/ui-carousel", label: "Carousel" },
  //         { id: 616, link: "/ui-dropdowns", label: "Dropdowns" },
  //         { id: 617, link: "/ui-utilities", label: "Utilities", badge: { text: "New", color: "bg-success" } },
  //         { id: 618, link: "/ui-grid", label: "Grid" },
  //         { id: 619, link: "/ui-images", label: "Images" },
  //         { id: 620, link: "/ui-lightbox", label: "Lightbox" },
  //         { id: 621, link: "/ui-modals", label: "Modals" },
  //         { id: 622, link: "/ui-colors", label: "Colors", badge: { text: "New", color: "bg-warning" } },
  //         { id: 623, link: "/ui-offcanvas", label: "Offcanvas", badge: { text: "New", color: "bg-warning" } },
  //         { id: 624, link: "/ui-pagination", label: "Pagination" },
  //         { id: 625, link: "/ui-popover-tooltip", label: "Popover & Tooltips" },
  //         { id: 626, link: "/ui-rangeslider", label: "Range Slider" },
  //         { id: 627, link: "/ui-session-timeout", label: "Session Timeout" },
  //         { id: 628, link: "/ui-progressbars", label: "Progress Bars" },
  //         { id: 629, link: "/ui-tabs-accordions", label: "Tabs & Accordions" },
  //         { id: 630, link: "/ui-typography", label: "Typography" },
  //         { id: 631, link: "/ui-video", label: "Video" },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   label: "Forms",
  //   subItems: [
  //     { id: 71, link: "/form-elements", label: "Form Elements" },
  //     { id: 72, link: "/form-validation", label: "Form Validation" },
  //     { id: 73, link: "/form-advanced", label: "Form Advanced" },
  //     { id: 74, link: "/form-editors", label: "Form Editors" },
  //     { id: 75, link: "/form-uploads", label: "Form File Upload" },
  //     { id: 76, link: "/form-xeditable", label: "Form Xeditable" },
  //   ],
  // },
  // {
  //   id: 8,
  //   label: "Charts",
  //   subItems: [
  //     { id: 81, link: "/apex-charts", label: "Apex charts" },
  //     { id: 82, link: "/charts-chartjs", label: "Chartjs Chart" },
  //     { id: 83, link: "/sparkline-charts", label: "Sparkline Chart" },
  //     { id: 84, link: "/charts-c3", label: "C3 Chart" },
  //     { id: 85, link: "/charts-knob", label: "Jquery Knob Chart" },
  //   ],
  // },
  // {
  //   id: 9,
  //   label: "Tables",
  //   subItems: [
  //     { id: 91, link: "/tables-basic", label: "Basic Tables" },
  //     { id: 92, link: "/tables-datatable", label: "Data Tables" },
  //     { id: 93, link: "/tables-responsive", label: "Responsive Table" },
  //     { id: 94, link: "/tables-editable", label: "Editable Table" },
  //   ],
  // },
  // {
  //   id: 10,
  //   label: "Icons",
  //   subItems: [
  //     { id: 101, link: "/icons-materialdesign", label: "Material Design" },
  //     { id: 102, link: "/icons-ion", label: "Ion Icons" },
  //     { id: 103, link: "/icons-fontawesome", label: "Font Awesome" },
  //     { id: 104, link: "/icons-themify", label: "Themify Icons" },
  //     { id: 105, link: "/icons-dripicons", label: "Dripicons" },
  //     { id: 106, link: "/icons-typicons", label: "Typicons Icons" },
  //   ],
  // },
  // {
  //   id: 11,
  //   label: "Maps",
  //   subItems: [
  //     { id: 111, link: "/maps-google", label: "Google Maps" },
  //     { id: 112, link: "/maps-vector", label: "Vector Maps" },
  //   ],
  // },
  // {
  //   id: 12,
  //   label: "Extras",
  //   subItems: [
  //     {
  //       id: 121,
  //       label: "Authentication",
  //       subItems: [
  //         { id: 1211, link: "/pages-login", label: "Login" },
  //         { id: 1212, link: "/pages-register", label: "Register" },
  //         { id: 1213, link: "/page-recoverpw", label: "Recover Password" },
  //         { id: 1214, link: "/auth-lock-screen", label: "Lock Screen" },
  //       ],
  //     },
  //     {
  //       id: 122,
  //       label: "Extra Pages",
  //       subItems: [
  //         { id: 1221, link: "/pages-timeline", label: "Timeline" },
  //         { id: 1222, link: "/pages-invoice", label: "Invoice" },
  //         { id: 1223, link: "/pages-directory", label: "Directory" },
  //         { id: 1224, link: "/pages-blank", label: "Blank Page" },
  //         { id: 1225, link: "/pages-404", label: "Error 404" },
  //         { id: 1226, link: "/pages-500", label: "Error 500" },
  //       ],
  //     },
  //     {
  //       id: 123,
  //       label: "Multi Level",
  //       subItems: [
  //         { id: 1231, link: "/multi-level-1", label: "Level 1.1" },
  //         {
  //           id: 1232,
  //           label: "Level 1.2",
  //           subItems: [
  //             { id: 12321, link: "/multi-level-2-1", label: "Level 2.1" },
  //             { id: 12322, link: "/multi-level-2-2", label: "Level 2.2" },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]
