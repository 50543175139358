import axios from "axios";
import { base_url } from '.';

export const uploadExcel = async (file, formData) => {
 
  return await axios.post("https://camp-coding.tech/KSB/" + "products/upload_excel_file.php", formData, {
    headers: {
      "Content-Type":"multipart/form-data"
    }
  });
};
